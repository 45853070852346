import React from 'react'

function Getstarted() {
   return (
      <div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>Getting started</h1>
         </div>
         <div class="
   w-full
   bg-[#ffba44] bg-opacity-[14%]
   px-6
   py-7
   md:p-9
   rounded-lg
   shadow-md
   flex
   border-l-[6px] border-[#ffba44]
   ">
            <div class="
      max-w-[36px]
      w-full
      h-9
      flex
      items-center
      justify-center
      rounded-lg
      mr-5
      bg-[#ffba44]
      ">
               <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.4917 7.65579L11.106 12.2645C11.2545 12.4128 11.4715 12.5 11.6738 12.5C11.8762 12.5 12.0931 12.4128 12.2416 12.2645C12.5621 11.9445 12.5623 11.4317 12.2423 11.1114C12.2422 11.1113 12.2422 11.1113 12.2422 11.1113C12.242 11.1111 12.2418 11.1109 12.2416 11.1107L7.64539 6.50351L12.2589 1.91221L12.2595 1.91158C12.5802 1.59132 12.5802 1.07805 12.2595 0.757793C11.9393 0.437994 11.4268 0.437869 11.1064 0.757418C11.1063 0.757543 11.1062 0.757668 11.106 0.757793L6.49234 5.34931L1.89459 0.740581L1.89396 0.739942C1.57364 0.420019 1.0608 0.420019 0.740487 0.739944C0.42005 1.05999 0.419837 1.57279 0.73985 1.89309L6.4917 7.65579ZM6.4917 7.65579L1.89459 12.2639L1.89395 12.2645C1.74546 12.4128 1.52854 12.5 1.32616 12.5C1.12377 12.5 0.906853 12.4128 0.758361 12.2645L1.1117 11.9108L0.758358 12.2645C0.437984 11.9445 0.437708 11.4319 0.757539 11.1116C0.757812 11.1113 0.758086 11.111 0.75836 11.1107L5.33864 6.50287L0.740487 1.89373L6.4917 7.65579Z" fill="#ffffff" stroke="#ffffff"></path>
               </svg>
            </div>
            <div class="w-full">
               <h5 class="text-base font-semibold mb-3 text-[#B45454]">
                  Note:
               </h5>
               <ul class="list-disc list-inside">
                  <li class="text-base text-[#fead21] leading-relaxed">
                     This guide was updated on December 18, 2022.
                  </li>
               </ul>
            </div>
         </div>
         <div class="shadow-md py-10 px-6 my-12">
            <ol>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     1
                  </span>
                  Basic functions of Qtree
               </li>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     2
                  </span>
                  How to create a Queue list
               </li>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     3
                  </span>
                  How to Add customer manually
               </li>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     4
                  </span>
                  How customers can join Queue automatically
               </li>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     5
                  </span>
                  How to organise Queue
               </li>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     5
                  </span>
                  How to manage Reneging
               </li>
            </ol>
         </div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>How to  join a queue</h1>
         </div>
         <div class="shadow-md py-10 px-6 my-12">
            <ol>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     1
                  </span>
                  How queue operates
               </li>
               <li class="flex text-base text-body-color mb-4">
                  <span class="
              bg-[#ffba44]
              text-white
              rounded-full
              mr-2
              text-base
              flex
              items-center
              justify-center
              h-6
              max-w-[24px]
              w-full
              ">
                     2
                  </span>
                  How to leave a queue
               </li>

            </ol>
         </div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>Basic functions of Qtree</h1>
         </div>
         <div class="rounded-md shadow-md mb-3 mt-2 py-2 px-4" style={{ color: "grey", textAlign: "left", fontSize: "19px", fontFamily: "revert", borderLeft: "3px solid #ffba44" }}>
            <p>
            Qtree offers two types of queue management: organization management and small business. Organization management is geared towards managing large queues and includes data storage for analysis, while small business management is designed for smaller operations and includes functions for managing queues for free. However, small business data is only stored for a day, while organization management data is retained for analysis. By default, new users are registered for small business queue management. If you would like to upgrade to organization management, you can visit your dashboard. Please note that at the time of writing, organization management is not currently available. </p>
         </div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>How to create a queue list</h1>
         </div>
         <div class="rounded-md shadow-md mb-3 mt-2 py-2 px-4" style={{ color: "grey", textAlign: "left", fontSize: "19px", fontFamily: "revert", borderLeft: "3px solid #ffba44" }}>
            <p>
            To create a queue, go to the homepage and click on the "queue" button. You will be prompted to enter the necessary information for your queue. Once you have completed this process, your queue will be added to your list of queues. To manage your queue, click on the queue you created and begin managing it.
            </p>
         </div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>How to add users manually </h1>
         </div>
         <div class="rounded-md shadow-md mb-3 mt-2 py-2 px-4" style={{ color: "grey", textAlign: "left", fontSize: "19px", fontFamily: "revert", borderLeft: "3px solid #ffba44" }}>
            <p>
            To add a user manually, follow these steps:

Go to your queue and click on "manage."
Click on "add user."
Enter the necessary information for the user.
Click on "submit."
Note: This method is primarily for users who do not have access to a mobile phone.

Alternatively, you can also allow users to scan your queue's QR code to add themselves to the queue.
            </p>
         </div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>How customers can join Queue automatically </h1>
         </div>
         <div class="rounded-md shadow-md mb-3 mt-2 py-2 px-4" style={{ color: "grey", textAlign: "left", fontSize: "19px", fontFamily: "revert", borderLeft: "3px solid #ffba44" }}>
            <p>
            Customers can join a queue in two ways: by scanning the queue's QR code or by using their location.

To join a queue by scanning its QR code, the user simply needs to scan the code with their device. The queue's details will then appear on the user's screen, and they can click "join" to add themselves to the queue.

To join a queue using location, the user must be within 1 meter of the queue's location. If this requirement is met, the user can click on "use location" to join the queue.</p>
         </div>

         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>How to organise Queue</h1>
         </div>
         <div class="rounded-md shadow-md mb-3 mt-2 py-2 px-4" style={{ color: "grey", textAlign: "left", fontSize: "19px", fontFamily: "revert", borderLeft: "3px solid #ffba44" }}>
            <p>
            Once a queue has more than five customers, the queue owner can begin to manage it. There are three basic functions for managing a queue for small businesses:

Next: This function sends a message to the individual currently at the front of the line, letting them know it is their turn.

Done: This function marks the user as having completed their transaction and removes them from the queue.

Hold: This function adds the customer to a "reneging" queue, allowing them to temporarily step out of the main queue and return later.

Overall, these functions allow the queue owner to efficiently manage their queue and keep track of their customers.</p>
         </div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>How to manage reneging. </h1>
         </div>
         <div class="rounded-md shadow-md mb-3 mt-2 py-2 px-4" style={{ color: "grey", textAlign: "left", fontSize: "19px", fontFamily: "revert", borderLeft: "3px solid #ffba44" }}>
            <p>
            Users may become frustrated if they have to wait on a queue for an extended period of time. To alleviate this frustration, a "reneging" queue can be used to allow users to temporarily step out of the main queue and return later while maintaining their position in line.

To join a queue, users can either scan the queue's QR code or use their location (if the option is available and they are within range of the queue's location). Alternatively, users may be able to manually add themselves to the queue by entering their information and submitting it through the queue's website or app.</p>
         </div>
         <div style={{ fontFamily: "monospace", fontSize: "30px", color: "grey", textAlign: "center", justifyContent: "space-between" }}>
            <h1>How queue operates </h1>
         </div>
         <div class="rounded-md shadow-md mb-3 mt-2 py-2 px-4" style={{ color: "grey", textAlign: "left", fontSize: "19px", fontFamily: "revert", borderLeft: "3px solid #ffba44" }}>
            <p>
            Queue operates in three different modes: "Done," "Hold," and "Next." When a user is selected from the queue, they are immediately notified. This helps to maintain queue discipline and ensure that the process is fair and efficient.

When a user completes their transaction or is removed from the queue, their status is updated to "done" or "deleted," respectively.

Additionally, users have the option to leave the queue by clicking "request leave" on their mobile phone. This will add the user to the "reneging" list, allowing them to temporarily step out of the queue and return later while maintaining their position in line.</p>
         </div>
         <div class="
   w-full
   bg-[#ffba44] bg-opacity-[14%]
   px-6
   py-7
   md:p-9
   rounded-lg
   shadow-md
   flex
   border-l-[6px] border-[2]
   ">
            <div class="
      max-w-[36px]
      w-full
      h-9
      flex
      items-center
      justify-center
      rounded-lg
      mr-5
      bg-[#ffba44]
      ">
               <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.4917 7.65579L11.106 12.2645C11.2545 12.4128 11.4715 12.5 11.6738 12.5C11.8762 12.5 12.0931 12.4128 12.2416 12.2645C12.5621 11.9445 12.5623 11.4317 12.2423 11.1114C12.2422 11.1113 12.2422 11.1113 12.2422 11.1113C12.242 11.1111 12.2418 11.1109 12.2416 11.1107L7.64539 6.50351L12.2589 1.91221L12.2595 1.91158C12.5802 1.59132 12.5802 1.07805 12.2595 0.757793C11.9393 0.437994 11.4268 0.437869 11.1064 0.757418C11.1063 0.757543 11.1062 0.757668 11.106 0.757793L6.49234 5.34931L1.89459 0.740581L1.89396 0.739942C1.57364 0.420019 1.0608 0.420019 0.740487 0.739944C0.42005 1.05999 0.419837 1.57279 0.73985 1.89309L6.4917 7.65579ZM6.4917 7.65579L1.89459 12.2639L1.89395 12.2645C1.74546 12.4128 1.52854 12.5 1.32616 12.5C1.12377 12.5 0.906853 12.4128 0.758361 12.2645L1.1117 11.9108L0.758358 12.2645C0.437984 11.9445 0.437708 11.4319 0.757539 11.1116C0.757812 11.1113 0.758086 11.111 0.75836 11.1107L5.33864 6.50287L0.740487 1.89373L6.4917 7.65579Z" fill="#ffffff" stroke="#ffffff"></path>
               </svg>
            </div>
            <div class="w-full">
               <h5 class="text-base font-semibold mb-3 text-[#B45454]">
                  Note:
               </h5>
               <ul class="list-disc list-inside">
                  <li class="text-base text-[#fead21] leading-relaxed">
                  To ensure optimal performance, it is important to follow the guidelines provided. If you have any questions or need further clarification on any part of the process, you can contact us on Twitter or Instagram.

This is the first guide written by Qtree, with contributions from the CEO and co-founder of Axgura. </li>
               </ul>
            </div>
         </div>

      </div>
   )
}

export default Getstarted